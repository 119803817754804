<script>
import { mapGetters } from "vuex";
import formatAccountType from "@/package/helpers/format-account-type";
import defineAccountType from "@/package/helpers/define-account-type";
import MainButton from "@/components/helpers/MainButton";

export default {
  name: "TransferSuccess",

  components: {
    MainButton,
  },

  props: {
    amount: {
      type: String,
      required: true,
    },

    transferAccountFrom: {
      type: Object,
      required: true,
    },

    transferAccountTo: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      getUserAccountById: "auth/getUserAccountById",
    }),

    formattedNumber() {
      return this.amount.toLocaleString("ru-RU");
    },

    formattedAccountFrom() {
      return formatAccountType(this.transferAccountFrom);
    },

    formattedAccountTo() {
      if (this.getUserAccountById(this.transferAccountTo.id)) {
        return formatAccountType(this.transferAccountTo);
      } else {
        return this.transferAccountTo.name;
      }
    },

    formattedAccountTypeTo() {
      if (this.getUserAccountById(this.transferAccountTo.id)) {
        return "Счет начисления";
      } else {
        return formatAccountType(this.transferAccountTo);
      }
    },

    showTransferToIcon() {
      return this.getUserAccountById(this.transferAccountTo.id);
    },
  },

  methods: {
    formatIconClass(account) {
      return `account__icon_${defineAccountType(account)}`;
    },
  },
};
</script>

<template>
  <div class="transfer-success">
    <div class="transfer-success__title">Переведено!</div>
    <div class="transfer-success__amount">{{ formattedNumber }} RC</div>

    <div class="transfer-success__account account">
      <div class="account__icon" :class="formatIconClass(transferAccountFrom)">
        <span></span>
      </div>
      <div class="account__wrapper">
        <p class="account__type">
          {{ formattedAccountFrom }}
        </p>
        <p class="account__transfer-type">Счет списания</p>
      </div>
    </div>

    <div class="transfer-success__account account">
      <div
        v-if="showTransferToIcon"
        class="account__icon"
        :class="formatIconClass(transferAccountTo)"
      >
        <span></span>
      </div>
      <div v-else class="account__image">
        <img src="../../assets/images/user_no_img.svg" alt="" />
      </div>
      <div class="account__wrapper">
        <p class="account__type">
          {{ formattedAccountTo }}
        </p>
        <p class="account__transfer-type">{{ formattedAccountTypeTo }}</p>
      </div>
    </div>

    <main-button
      title="Хорошо"
      color="dark"
      @click="$router.push({ name: 'Transfers' })"
    />
  </div>
</template>

<style scoped lang="scss">
.transfer-success {
  padding: $space-xxxl 0 0;

  &__title {
    color: $dark-primary;
    margin-bottom: $space-xl;
    @include title-3;
  }

  &__amount {
    color: $dark-primary;
    margin-bottom: $space-xl;
    @include text-1;
  }

  &__account {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 $space-l;

    &:last-child {
      margin: 0;
    }
  }

  .account {
    &__icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      overflow: hidden;
      background-color: $dark-primary;
      margin: 0 $space-s 0 0;

      &_distributor {
        span {
          mask: url("../../assets/icons/account-types/distributor.svg") 0 0 /
            cover no-repeat;
        }
      }

      &_cooperative {
        span {
          mask: url("../../assets/icons/account-types/cooperative.svg") 0 0 /
            cover no-repeat;
        }
      }

      &_client {
        span {
          mask: url("../../assets/icons/account-types/client.svg") 0 0 / cover
            no-repeat;
        }
      }

      span {
        display: inline-block;
        width: 24px;
        height: 24px;
        background: $gold-main;
      }
    }

    &__image {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin: 0 $space-s 0 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__type {
      @include text-2;
      color: $dark-primary;
      margin-bottom: $space-xxs;
    }

    &__transfer-type {
      color: $dark-sixth;
      @include caption-1;
    }
  }

  .main-button {
    margin: $space-xxl 0 0;
    max-width: 280px;
  }
}
</style>
