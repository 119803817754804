export default function formatAccountType(account) {
  switch (account.user_type) {
    case "distributor":
      switch (account.account_type) {
        case "rc":
          return "Дистрибьюторский счет";

        case "cooperative":
          return "Кооперативный счет";
      }

    case "client":
      switch (account.account_type) {
        case "rc":
          return "Клиентский счет";
      }
  }
}
