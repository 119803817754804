<script>
import formatAccountType from "@/package/helpers/format-account-type";

export default {
  props: {
    account: {
      type: Object,
      required: true,
    },
  },

  computed: {
    formattedNumber: function () {
      return `${this.account.summary_active.toLocaleString("ru-RU")} RC ${
        this.account.currency
      }`;
    },

    accountType() {
      return formatAccountType(this.account);
    },
  },
};
</script>

<template>
  <div class="transfer-selector-item">
    <div class="transfer-selector-item__title">
      {{ accountType }}
    </div>

    <div class="transfer-selector-item__balance">{{ formattedNumber }}</div>
  </div>
</template>

<style lang="scss" scoped>
.transfer-selector-item {
  cursor: pointer;
  margin: 0 0 $space-m;

  &:last-child {
    margin: 0;
  }

  &__title {
    color: $dark-fifth;
    @include text-2;
  }

  &__balance {
    color: $dark-primary;
    margin: $space-xs 0 0;
    @include body-1-bold;
  }
}
</style>
