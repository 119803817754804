<script>
import formatAccountType from "@/package/helpers/format-account-type";
import vClickOutside from "v-click-outside";
import TransferSelectorItem from "@/components/transfers/TransferSelectorItem";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },

  components: { TransferSelectorItem },

  props: {
    selectedAccount: {
      type: Object,
      default: null,
    },

    accounts: {
      type: Array,
      required: true,
    },

    label: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      showAccounts: false,
    };
  },

  computed: {
    filteredAccounts() {
      return this.selectedAccount
        ? this.accounts.filter(
            (account) => account.id !== this.selectedAccount.id
          )
        : this.accounts;
    },

    formattedNumber() {
      return this.selectedAccount.summary_active.toLocaleString("ru-RU");
    },

    accountType() {
      return formatAccountType(this.selectedAccount);
    },
  },

  methods: {
    hideAccounts() {
      this.showAccounts = false;
    },
  },
};
</script>

<template>
  <div class="transfer-selector__wrapper">
    <p v-if="label" class="transfer-selector__label">
      {{ label }}
    </p>

    <div
      v-click-outside="hideAccounts"
      class="transfer-selector"
      :class="{ 'transfer-selector_opened': showAccounts }"
      @click="showAccounts = !showAccounts"
    >
      <div v-if="selectedAccount" class="transfer-selector__info info">
        <div class="info__title">
          {{ accountType }}
        </div>

        <div class="info__balance">{{ formattedNumber }} RC</div>
      </div>

      <div v-else class="info__title">Выберите счет</div>

      <div class="transfer-selector__arrow">
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 2.5L4 5.5L7 2.5" stroke="#BDBDBD" />
        </svg>
      </div>

      <div v-if="showAccounts" class="transfer-selector__accounts">
        <transfer-selector-item
          v-for="account in filteredAccounts"
          :key="account.id"
          :account="account"
          @click.native="$emit('selectTransferAccount', account)"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.transfer-selector {
  max-width: 550px;
  padding: 20px $space-m;
  border-radius: 8px;
  background-color: $background-grey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;

  &_opened {
    background-color: $background-white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px 8px 0 0;
    cursor: default;

    &:after {
      content: "";
      position: absolute;
      width: calc(100% - 32px);
      height: 1px;
      bottom: 0;
      left: 16px;
      background-color: $light-fifth;
    }

    svg {
      transform: rotate(180deg);
    }
  }

  &__label {
    @include text-2;
    color: $dark-sixth;
    margin-bottom: $space-s;
  }

  .info {
    &__title {
      color: $dark-fifth;
      @include text-2;
    }

    &__balance {
      color: $dark-primary;
      margin: $space-xs 0 0;
      @include body-1-bold;
    }
  }

  &__accounts {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background-color: $background-white;
    padding: 20px $space-m;
    box-shadow: 0 16px 20px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 8px 8px;
    z-index: 2;
  }
}
</style>
