<script>
import { mapState } from "vuex";
import { TheMask } from "vue-the-mask";

export default {
  name: "TransferSum",
  components: {
    TheMask,
  },

  props: {
    value: {
      type: String,
      default: "",
    },

    transferType: {
      type: String,
      default: "transfer-to-account",
    },

    error: {
      type: Boolean,
      default: false,
    },

    errorMessage: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      amount: this.value,
    };
  },

  computed: {
    ...mapState({
      transferAccountFrom: (state) => state.transfer.transferAccountFrom,
    }),

    transferTax() {
      if (this.amount) {
        let tax;

        if (Math.round((this.amount / 100) * 3) <= 100) {
          tax = 100;
        } else {
          tax = Math.round((this.amount / 100) * 3);
        }

        return `Комиссия ${tax} RC`;
      } else {
        return "Комиссия 3%, минимум 100 RC ";
      }
    },
  },

  methods: {
    handleInput(e) {
      this.$emit("input", this.amount);
    },
  },
};
</script>

<template>
  <div class="transfer__sum">
    <div class="transfer__sum__title">Сумма (должна быть кратна 100)</div>

    <div
      class="transfer__sum__input"
      :class="{ 'transfer__sum__input--error': error }"
    >
      <the-mask
        v-model="amount"
        mask="#################"
        masked
        type="text"
        @input="handleInput"
      />

      <div v-if="amount" class="transfer__sum__input__rc">RC</div>
    </div>

    <p
      v-if="transferType === 'transfer-to-account' && !error"
      class="transfer__sum__notice"
    >
      Минимальная сумма - 100 RC
    </p>

    <p
      v-if="transferType === 'transfer-to-client' && !error"
      class="transfer__sum__notice"
    >
      {{ transferTax }}
    </p>

    <p v-if="error" class="transfer__sum__error">
      {{ errorMessage }}
    </p>
  </div>
</template>

<style scoped lang="scss">
.transfer__sum {
  display: inline-flex;
  flex-flow: column;

  @media (max-width: 768px) {
    width: 100%;
  }

  &__title {
    color: $dark-sixth;
    margin: 0 0 $space-s;
    @include text-2;
  }

  &__input {
    position: relative;

    @media (max-width: 768px) {
      max-width: 550px;
    }

    &--error {
      > input {
        border: 1px solid $red !important;
      }
    }

    > input {
      width: 320px;
      background: $background-grey;
      border: 1px solid $light-fifth;
      border-radius: 8px;
      color: $dark-primary;
      padding: 13px $space-m;
      outline: none;
      @include text-2;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    &__rc {
      position: absolute;
      top: 14px;
      right: 16px;
      color: $dark-primary;
      @include body-1-bold;
    }
  }

  &__notice {
    @include caption-1;
    color: $dark-sixth;
    padding-left: $space-s;
    margin-top: $space-xs;
  }

  &__error {
    @include caption-1;
    color: $red;
    padding-left: $space-s;
    margin-top: $space-xs;
  }
}
</style>
