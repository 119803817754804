export default function defineAccountType(account) {
  switch (account.user_type) {
    case "distributor":
      switch (account.account_type) {
        case "rc":
          return "distributor";

        case "cooperative":
          return "cooperative";
      }

    case "client":
      switch (account.account_type) {
        case "rc":
          return "client";
      }
  }
}
